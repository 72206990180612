<template>
    <a-modal
        ref="createModal"
        :title="readOnly ? '详情' : form.clientId ? '客户编辑' : '新增操作'"
        :width="640"
        :visible="visible"
        @cancel="close"
        @ok="confirm"
        :confirmLoading="confirmLoading"
        :okButtonProps="{
            props: {
                disabled: readOnly ? true : false,
            },
        }"
    >
        <a-spin :spinning="loading">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row>
                    <a-col :span="12">
                        <a-form-model-item label="客户名称" prop="company">
                            <a-input v-model="form.company" placeholder="客户名称" :disabled="readOnly" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="状态" prop="status">
                            <a-radio-group v-model="form.status" button-style="solid">
                                <a-radio-button
                                v-for="dict in statusOptions"
                                :key="dict.dictValue"
                                :value="dict.dictValue">
                                {{ dict.dictLabel }}
                                </a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="客户分类" prop="classification">
                            <a-select v-model="form.classification" placeholder="选择客户分类" :disabled="readOnly">
                                <a-select-option v-for="dict in classificationOptions"
                                    :key="dict.dictValue"
                                    :value="dict.dictValue">
                                {{ dict.dictLabel }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="行业" prop="industry">
                            <a-select v-model="form.industry" placeholder="选择客户行业" :disabled="readOnly">
                                <a-select-option v-for="dict in industryOptions"
                                    :key="dict.dictValue"
                                    :value="dict.dictValue">
                                {{ dict.dictLabel }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="地址" prop="company">
                            <a-input v-model="form.address" placeholder="地址" :disabled="readOnly" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="商务经理" prop="manageUserId">
                            <a-select v-model="form.managerUserId" placeholder="选择商务经理" :disabled="readOnly">
                                <!-- <a-select-option value=3>derek</a-select-option>
                                <a-select-option value=6>asas</a-select-option>
                                <a-select-option value=11>asdfffff</a-select-option> -->
                                <a-select-option v-for="item in bmList"
                                    :key="item.userId"
                                    :value="item.userId">
                                {{ item.nickName }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-spin>
    </a-modal>
</template>

<script>
// import { listUniversity } from '@/api/rongyi/organization/university'
import { addClient, updateClient } from '@/api/rongyi/staff/client'
import { getUserListByRole } from '@/api/system/user'
import rryConfig from '@/../config/rongyiYun.config'
import moment from 'moment'
// 表单字段
export default {
    data() {
        return {
            parentId: undefined,
            config: rryConfig,
            logoUrl: '',
            token: '',
            temp: {},
            confirmLoading: false,
            readOnly: false,
            visible: false,
            loading: false,
            // form: this.$form.createForm(this),
            // value: undefined,
            labelCol: {
                xs: { span: 12 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            // 表单属性:需要设定默认值的字段必须先定义(Vue的响应式property必须在初始化的时候声明)
            form: {
                managerUserId:undefined,
            },
            // 表单校验
            rules: {
                universityId: [{ required: true, message: '请选择学校', trigger: 'blur' }],
                email: [
                    {
                        required: true,
                        type: 'email',
                        message: '请输入正确的邮箱地址',
                        trigger: ['blur', 'change'],
                    },
                ],
                linkmanPhone: [
                    {
                        required: true,
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur',
                    },
                ],
                postcode: [
                    {
                        required: true,
                        pattern: /[1-9][0-9]{5}/,
                        message: '请输入正确的邮编',
                        trigger: 'blur',
                    },
                ],
                // address: [{ required: true, message: '地址不能为空', trigger: 'blur' }],
                linkman: [{ required: true, message: '联系人不能为空', trigger: 'blur' }],
            },
            // universityList:[],
            //商务经理列表
            bmList:[],
            // 分类数据字典
            classificationOptions: [],
            // 行业数据字典
            statusOptions: [],
            // 行业数据字典
            industryOptions: [],
        }
    },
    created() {
        
        this.getDicts('rongyi_client_classification').then(response => {
            this.classificationOptions = response.data
        })
        this.getDicts('rongyi_client_industry').then(response => {
            this.industryOptions = response.data
        })
        this.getDicts('sys_normal_disable').then(response => {
            this.statusOptions = response.data
        })

        // this.getbmList().then(res=>{

        //     this.form.managerUserId = 3;
        // });
    },
    methods: {
        // 由于要用传进来的值做判断,将显示和隐藏放在内部做处理
        
        // getUnviersityList() {
        //     const p = new Promise((resolve,reject)=>{
        //         let self = this
        //         listUniversity().then((response) => {
        //             if(response.code == 200){
        //                 self.universityList = response.rows
        //                 resolve(response.rows)
        //             }else{
        //                 reject(response.msg)
        //             }
        //         })
        //     });
        //     return p;
        // },
        getbmList() {
            const p = new Promise((resolve,reject)=>{
            console.log('this.bmList')
            console.log(this.bmList)
                let self = this
                let params = {
                    roleName:'商务经理'
                }
                getUserListByRole(params).then((response) => {
                    if(response.code == 200){
                        self.bmList = response.rows
                        resolve(response.rows)
                    }else{
                        reject(response.msg)
                    }
                })
            });
            return p;
        },
        show(data, readOnly) {
            // this.form.universityId = universityId
            // this.form.departmentId = departmentId
            // this.form.gradeId = gradeId
            // this.form.specializeId = specializeId
            // this.form.specializeId = specializeId
            // this.form.classId = classId
            console.log('data');
            console.log(data);
            if (data) {
                // 修改行为
                this.form = Object.assign({}, data) || {}
                //client表中存储的managerUserId是字符型，user表中的userId是整形
                this.form.managerUserId = parseInt(this.form.managerUserId)
            } else {
                console.log('this.form2')
                console.log(this.form)
                // this.form.managerUserId = 0
                // 新增行为
                this.resetForm()
            }
            this.readOnly = typeof readOnly !== 'undefined' ? readOnly === true : false
            this.visible = true
        },
        // 关闭模态框
        close() {
            this.visible = false
            this.resetForm()
        },
        confirm() {
            let that = this
            console.log('form')
            console.log(this.form)
            this.confirmLoading = true
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    console.log('valid success')
                    console.log(this.form)
                    // 进行新增行为:
                    if (!that.isEmptyStr(this.form.clientId)) {
                        // 刷新表格
                        updateClient(this.form).then((response) => {
                            if (response.code === 200) {
                                this.$message.success('修改成功')
                                // 关闭本组件
                                this.visible = false
                                // 调用外部刷新列表方法
                                this.$emit('handle-success')
                                // 刷新表单
                                this.resetForm()
                                this.confirmLoading = false
                            } else {
                                this.$message.error(response.msg)
                                this.confirmLoading = false
                            }
                        })
                    } else {
                        console.log("new client form");console.log(this.form);
                        // 新增
                        addClient(this.form).then((response) => {
                            if (response.code === 200) {
                                this.$message.success('新增成功')
                                // 关闭本组件
                                this.visible = false
                                // 调用外部刷新列表方法
                                this.$emit('handle-success')
                                // 刷新表单
                                this.resetForm()
                                this.confirmLoading = false
                            } else {
                                this.$message.error(response.msg)
                                this.confirmLoading = false
                            }
                        })
                    }
                } else {
                    console.log('valid false')
                    console.log(this.form)
                    return (this.confirmLoading = false)
                }
            })
        },
        // 表单重置
        resetForm() {
            for(var key in this.form){
                this.form[key] = undefined;
            }
            //默认值
            // this.form.politicalFace = '13'
            // this.form.universityId = this.$store.state.user.host_id || '00000000000000000000000000000000'
            // this.form.sex='0'
            // this.form.birthDate = moment(new Date('1985/1/1'))
        },
        filterStrArrary(list) {
            for (let index = 0; index < list.length; index++) {
                if (list[index].children === '') {
                    list[index].children = []
                }
                if (list[index].children) {
                    this.filterStrArrary(list[index].children)
                }
            }
        },
    },
}
</script>
<style scoped>
.avatar {
    width: 128px;
    height: 128px;
}
.avatar-uploader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>