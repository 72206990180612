<template>
    <page-header-wrapper>
        <template v-slot:content>
            <div class="page-header-content">
                <a-card :bordered="false" class="content">
                    <div class="table-page-search-wrapper">
                        <a-form layout="inline" ref="queryForm">
                            <a-row :gutter="48">
                                <a-col :md="6" :sm="18">
                                    <a-form-item label="客户名称">
                                        <a-input placeholder="请输入" v-model="queryParams.company" />
                                    </a-form-item>
                                </a-col>
                                <a-col :md="6" :sm="18">
                                    <span class="table-page-search-submitButtons">
                                        <a-button @click="handleQuery" type="primary">查询</a-button>
                                        <a-button @click="resetQuery" style="margin-left: 8px">重置</a-button>
                                    </span>
                                </a-col>
                            </a-row>
                        </a-form>
                    </div>
                    <div class="table-page-operator-wrapper">
                        <a-button
                            @click="handleAddClientClick"
                            type="primary"
                            v-hasPermi="['staff:client:add']"
                            ghost
                            >新增</a-button
                        >
                    </div>
                    <!-- 表格 -->
                    <a-table
                        ref="table"
                        :columns="columns"
                        :loading="tableLoading"
                        :data-source="clientList"
                        row-key="clientId"
                        :pagination="false"
                    >
                        <!-- 插槽指向状态 -->
                        <span slot="classification" slot-scope="text, record">
                            {{ classificationFormat(record) }}
                        </span>
                        <span slot="industry" slot-scope="text, record">
                            {{ industryFormat(record) }}
                        </span>
                        <span slot="status" slot-scope="text, record">
                            {{ statusFormat(record) }}
                        </span>
                        <!-- 更多选择 -->
                        <span slot="action" slot-scope="text, record">
                            <a
                                 @click="handleAccountLinkClick(record)"
                                v-hasPermi="['sys:user:query']"
                                >{{record.userId?'修改账号':'创建账号'}}</a
                            >
                            <a-divider type="vertical" />
                            <a
                                @click="$refs.createModal.show(record, true)"
                                v-hasPermi="['organization:class:query']"
                                >详细</a
                            >
                            <a-divider type="vertical" />
                            <a
                                @click="$refs.createModal.show(record, false)"
                                v-hasPermi="['organization:class:edit']"
                                >编辑</a
                            >
                            <a-divider type="vertical" />
                            <a
                                href="javascript:;"
                                v-hasPermi="['organization:class:remove']"
                                @click="handleDelete(record)"
                                >删除</a
                            >
                            <a-dropdown v-if="false">
                                <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
                                <a-menu slot="overlay">
                                    <a-menu-item>
                                        <a
                                            href="javascript:;"
                                            v-hasPermi="['organization:class:remove']"
                                            @click="handleDelete(record)"
                                            >删除</a
                                        >
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </span>
                    </a-table>
                    <!-- 底部分页按钮 -->
                    <a-pagination
                        class="ant-table-pagination"
                        v-model="current"
                        :page-size-options="pageSizeOptions"
                        :total="total"
                        show-size-changer
                        :page-size="pageSize"
                        @showSizeChange="onShowSizeChange"
                        @change="currentPageChange"
                    >
                        <template slot="buildOptionText" slot-scope="props">
                            <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
                            <span v-if="props.value === '50'">全部</span>
                        </template>
                    </a-pagination>
                    <!-- 创建/编辑部门,单独封装了组件 -->
                    <create-form ref="createModal" @handle-success="handleOk" />
                    <reg-form ref="regModal" @handle-success="handleOk" />
                </a-card>
            </div>
        </template>
    </page-header-wrapper>
</template>

<script>
// import { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import { listClient } from '@/api/rongyi/staff/client'
import { getUser } from '@/api/system/user'
import CreateForm from './modules/CreateForm'
import RegForm from './modules/RegForm'
const statusMap = {
    0: {
        status: 'processing',
        text: '正常',
    },
    1: {
        status: 'default',
        text: '停用',
    },
}
export default {
    name: 'class',
    components: {
        CreateForm,
        RegForm,
    },
    data() {
        return {
            clientList:[],
            // 分页数据(默认第一页):
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            current: 1,
            pageSize: 10,
            total: 0,
            statusMap: [],
            tableLoading: false,
            // 分类数据字典
            classificationOptions: [],
            // 行业数据字典
            statusOptions: [],
            // 行业数据字典
            industryOptions: [],
            // 查询参数
            queryParams: {
                // universityId:undefined,

            },
            // 表头
            columns: [
                {
                    title: '客户名称',
                    dataIndex: 'company',
                },
                {
                    title: '客户类别',
                    dataIndex: 'classification',
                    scopedSlots: { customRender: 'classification' }
                },
                {
                    title: '所属行业',
                    dataIndex: 'industry',
                    scopedSlots: { customRender: 'industry' }
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    scopedSlots: { customRender: 'status' }
                },
                {
                    title: '操作',
                    width: '150',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' },
                },
            ],
            // 表格多选
            selectedRowKeys: [],
            selectedRows: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys
                    this.selectedRows = selectedRows
                },
                onSelect: (record, selected, selectedRows) => {},
                onSelectAll: (selected, selectedRows, changeRows) => {},
            },
        }
    },
    // 状态过滤
    filters: {
        statusFilter(type) {
            return statusMap[type].text
        },
        statusTypeFilter(type) {
            return statusMap[type].status
        },
    },
    created() {
        
        this.getDicts('rongyi_client_classification').then(response => {
            this.classificationOptions = response.data
        })
        this.getDicts('rongyi_client_industry').then(response => {
            this.industryOptions = response.data
        })
        this.getDicts('sys_normal_disable').then(response => {
            this.statusOptions = response.data
        })
        this.getClientList().then(console.log(this.clientList));
    },
    watch: {
        '$route' (to, from) {
            
        }
    },
    methods: {
        getClientList() {
            const p = new Promise((resolve,reject)=>{
                listClient(this.queryParams).then((response) => {
                    if(response.code == 200){
                        this.clientList = response.rows
                        this.total = response.total
                        
                        resolve(response)
                    }else{
                        reject(response.msg)
                    }
                })
            });
            return p;
        },
        /** 转换部门数据结构 */
        // normalizer(node) {
        //     if (node.children && !node.children.length) {
        //         delete node.children
        //     }
        //     return {
        //         id: node.deptId,
        //         label: node.deptName,
        //         children: node.children,
        //     }
        // },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getClientList()
        },
        /** 重置按钮操作 */
        resetQuery() {
            // this.queryParams.teacherName = undefined
            // this.queryParams.universityId = undefined
            this.handleQuery()
        },
        handleAccountLinkClick(record){
            let account = {}
            if(record.userId){
                getUser(record.userId).then(res=>{
                    if(res.code==200){
                        account = res.data;
                        this.$refs.regModal.show(account, false, record);
                    }
                })
            }else{
                //新增
                console.log('新增')
                console.log(record)
                account.nickName = record.company
                // account.phonenumber = record.perPhone
                // account.email = record.email
                account.password = '123456'
                // account.sex = record.sex
                account.status = record.status
                account.hostType = 2
                account.hostId = record.clientId
                account.staffType = 4
                account.staffId = record.clientId
                account.postIds = []
                account.roleIds = [9]
                // account.roles= [{
                //     roleId: 8,
                //     roleKey: "sdt",
                //     roleName: "学生",
                //     roleSort: "8",

                // }]
                this.$refs.regModal.show(account, false, record);
            }
        },
        handleAddClientClick(){
            // if(isEmptyStr(this.queryParams.universityId)){
            //     this.$message.warning("请先选择学校")
            //     return;
            // }
            this.$refs.createModal.show(null, false,this.queryParams.clientId);
        },
        // 新增/修改框事件
        handleOk() {
            this.getClientList().then();
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            this.$confirm({
                title: '警告',
                content: `真的要删除 名为${row.company}的数据项吗?`,
                okText: '删除',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    delClient(row.specializeId).then((response) => {
                        if (response.code === 200) {
                            that.$message.success('删除成功!')
                            that.getList()
                        } else {
                            that.$message.error(response.msg)
                        }
                    })
                },
            })
        },
        /** pageSize 变化的回调 */
        onShowSizeChange(current, pageSize) {
            this.current = current
            this.pageSize = pageSize
            this.queryParams.pageSize = pageSize
            this.queryParams.pageNum = current
            this.getClientList()
        },
        /** 页码改变的回调 */
        currentPageChange(current, pageSize) {
            this.current = current
            this.pageSize = pageSize
            this.queryParams.pageSize = pageSize
            this.queryParams.pageNum = current
            this.getClientList()
        },
        // 状态字典翻译
        statusFormat (row) {
            return this.selectDictLabel(this.statusOptions, row.status)
        },
        // 分类字典翻译
        classificationFormat (row) {
            return this.selectDictLabel(this.classificationOptions, row.classification)
        },
        // 行业字典翻译
        industryFormat (row) {
            return this.selectDictLabel(this.industryOptions, row.industry)
        },
    },
}
</script>
<style scoped>
.avatar {
    width: 32px;
    height: 32px;
}
</style>